export const ScAddressLink = () => import('../../node_modules/@evince/site/lib/packages/AddressLink.vue' /* webpackChunkName: "components/sc-address-link" */).then(c => wrapFunctional(c.default || c))
export const ScBrowserList = () => import('../../node_modules/@evince/site/lib/packages/BrowserList.vue' /* webpackChunkName: "components/sc-browser-list" */).then(c => wrapFunctional(c.default || c))
export const ScBusinessHours = () => import('../../node_modules/@evince/site/lib/packages/BusinessHours.vue' /* webpackChunkName: "components/sc-business-hours" */).then(c => wrapFunctional(c.default || c))
export const ScCookieBar = () => import('../../node_modules/@evince/site/lib/packages/CookieBar.vue' /* webpackChunkName: "components/sc-cookie-bar" */).then(c => wrapFunctional(c.default || c))
export const ScDirectionsLink = () => import('../../node_modules/@evince/site/lib/packages/DirectionsLink.vue' /* webpackChunkName: "components/sc-directions-link" */).then(c => wrapFunctional(c.default || c))
export const ScDynamicForm = () => import('../../node_modules/@evince/site/lib/packages/DynamicForm.vue' /* webpackChunkName: "components/sc-dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const ScEmailLink = () => import('../../node_modules/@evince/site/lib/packages/EmailLink.vue' /* webpackChunkName: "components/sc-email-link" */).then(c => wrapFunctional(c.default || c))
export const ScFaxLink = () => import('../../node_modules/@evince/site/lib/packages/FaxLink.vue' /* webpackChunkName: "components/sc-fax-link" */).then(c => wrapFunctional(c.default || c))
export const ScLangSwitcher = () => import('../../node_modules/@evince/site/lib/packages/LangSwitcher.vue' /* webpackChunkName: "components/sc-lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const ScMainNav = () => import('../../node_modules/@evince/site/lib/packages/MainNav.vue' /* webpackChunkName: "components/sc-main-nav" */).then(c => wrapFunctional(c.default || c))
export const ScMobileLink = () => import('../../node_modules/@evince/site/lib/packages/MobileLink.vue' /* webpackChunkName: "components/sc-mobile-link" */).then(c => wrapFunctional(c.default || c))
export const ScMobileMenu = () => import('../../node_modules/@evince/site/lib/packages/MobileMenu.vue' /* webpackChunkName: "components/sc-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const ScNewsletterForm = () => import('../../node_modules/@evince/site/lib/packages/NewsletterForm.vue' /* webpackChunkName: "components/sc-newsletter-form" */).then(c => wrapFunctional(c.default || c))
export const ScPhoneLink = () => import('../../node_modules/@evince/site/lib/packages/PhoneLink.vue' /* webpackChunkName: "components/sc-phone-link" */).then(c => wrapFunctional(c.default || c))
export const ScSocialIcons = () => import('../../node_modules/@evince/site/lib/packages/SocialIcons.vue' /* webpackChunkName: "components/sc-social-icons" */).then(c => wrapFunctional(c.default || c))
export const ScViberLink = () => import('../../node_modules/@evince/site/lib/packages/ViberLink.vue' /* webpackChunkName: "components/sc-viber-link" */).then(c => wrapFunctional(c.default || c))
export const ScWhatsappLink = () => import('../../node_modules/@evince/site/lib/packages/WhatsappLink.vue' /* webpackChunkName: "components/sc-whatsapp-link" */).then(c => wrapFunctional(c.default || c))
export const ScContactButton = () => import('../../node_modules/@evince/site/lib/packages/contact/Button.vue' /* webpackChunkName: "components/sc-contact-button" */).then(c => wrapFunctional(c.default || c))
export const ScContactDetails = () => import('../../node_modules/@evince/site/lib/packages/contact/Details.vue' /* webpackChunkName: "components/sc-contact-details" */).then(c => wrapFunctional(c.default || c))
export const ScContactForm = () => import('../../node_modules/@evince/site/lib/packages/contact/Form.vue' /* webpackChunkName: "components/sc-contact-form" */).then(c => wrapFunctional(c.default || c))
export const PageContact = () => import('../../node_modules/@evince/site/lib/templates/contact.vue' /* webpackChunkName: "components/page-contact" */).then(c => wrapFunctional(c.default || c))
export const PageRequest = () => import('../../node_modules/@evince/site/lib/templates/request.vue' /* webpackChunkName: "components/page-request" */).then(c => wrapFunctional(c.default || c))
export const HotelAvailabilityForm = () => import('../../node_modules/@evince/hotel/lib/packages/AvailabilityForm.vue' /* webpackChunkName: "components/hotel-availability-form" */).then(c => wrapFunctional(c.default || c))
export const HotelAvailabilityRequest = () => import('../../node_modules/@evince/hotel/lib/packages/AvailabilityRequest.vue' /* webpackChunkName: "components/hotel-availability-request" */).then(c => wrapFunctional(c.default || c))
export const ScMutationForm = () => import('../../node_modules/@evince/core/lib/components/MutationForm.vue' /* webpackChunkName: "components/sc-mutation-form" */).then(c => wrapFunctional(c.default || c))
export const ScCountrySelect = () => import('../../node_modules/@evince/core/lib/components/country/Select.vue' /* webpackChunkName: "components/sc-country-select" */).then(c => wrapFunctional(c.default || c))
export const ScLanguageSelect = () => import('../../node_modules/@evince/core/lib/components/language/Select.vue' /* webpackChunkName: "components/sc-language-select" */).then(c => wrapFunctional(c.default || c))
export const ErrorDetails = () => import('../../node_modules/@evince/core/lib/packages/ErrorDetails.vue' /* webpackChunkName: "components/error-details" */).then(c => wrapFunctional(c.default || c))
export const PwaInstall = () => import('../../node_modules/@evince/core/lib/packages/PwaInstall.vue' /* webpackChunkName: "components/pwa-install" */).then(c => wrapFunctional(c.default || c))
export const PwaPrompt = () => import('../../node_modules/@evince/core/lib/packages/PwaPrompt.vue' /* webpackChunkName: "components/pwa-prompt" */).then(c => wrapFunctional(c.default || c))
export const StateLabel = () => import('../../node_modules/@evince/core/lib/packages/StateLabel.vue' /* webpackChunkName: "components/state-label" */).then(c => wrapFunctional(c.default || c))
export const GraphqlMutation = () => import('../../node_modules/@evince/core/lib/packages/graphql/Mutation.vue' /* webpackChunkName: "components/graphql-mutation" */).then(c => wrapFunctional(c.default || c))
export const GraphqlQuery = () => import('../../node_modules/@evince/core/lib/packages/graphql/Query.vue' /* webpackChunkName: "components/graphql-query" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const PageBox = () => import('../../components/PageBox.vue' /* webpackChunkName: "components/page-box" */).then(c => wrapFunctional(c.default || c))
export const BookingBanner = () => import('../../components/booking/Banner.vue' /* webpackChunkName: "components/booking-banner" */).then(c => wrapFunctional(c.default || c))
export const BookingButton = () => import('../../components/booking/Button.vue' /* webpackChunkName: "components/booking-button" */).then(c => wrapFunctional(c.default || c))
export const BookingCta = () => import('../../components/booking/Cta.vue' /* webpackChunkName: "components/booking-cta" */).then(c => wrapFunctional(c.default || c))
export const BookingForm = () => import('../../components/booking/Form.vue' /* webpackChunkName: "components/booking-form" */).then(c => wrapFunctional(c.default || c))
export const FooterColumn = () => import('../../components/footer/Column.vue' /* webpackChunkName: "components/footer-column" */).then(c => wrapFunctional(c.default || c))
export const FooterColumns = () => import('../../components/footer/Columns.vue' /* webpackChunkName: "components/footer-columns" */).then(c => wrapFunctional(c.default || c))
export const FooterCopyright = () => import('../../components/footer/Copyright.vue' /* webpackChunkName: "components/footer-copyright" */).then(c => wrapFunctional(c.default || c))
export const LayoutBase = () => import('../../components/layout/Base.vue' /* webpackChunkName: "components/layout-base" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../components/layout/Footer.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeader = () => import('../../components/layout/Header.vue' /* webpackChunkName: "components/layout-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutImage = () => import('../../components/layout/Image.vue' /* webpackChunkName: "components/layout-image" */).then(c => wrapFunctional(c.default || c))
export const LayoutImageHeader = () => import('../../components/layout/ImageHeader.vue' /* webpackChunkName: "components/layout-image-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutInfoBar = () => import('../../components/layout/InfoBar.vue' /* webpackChunkName: "components/layout-info-bar" */).then(c => wrapFunctional(c.default || c))
export const LocationDestinations = () => import('../../components/location/Destinations.vue' /* webpackChunkName: "components/location-destinations" */).then(c => wrapFunctional(c.default || c))
export const LocationList = () => import('../../components/location/List.vue' /* webpackChunkName: "components/location-list" */).then(c => wrapFunctional(c.default || c))
export const LocationMapButtons = () => import('../../components/location/MapButtons.vue' /* webpackChunkName: "components/location-map-buttons" */).then(c => wrapFunctional(c.default || c))
export const NewsletterSubscribe = () => import('../../components/newsletter/Subscribe.vue' /* webpackChunkName: "components/newsletter-subscribe" */).then(c => wrapFunctional(c.default || c))
export const RoomList = () => import('../../components/room/List.vue' /* webpackChunkName: "components/room-list" */).then(c => wrapFunctional(c.default || c))
export const RoomModal = () => import('../../components/room/Modal.vue' /* webpackChunkName: "components/room-modal" */).then(c => wrapFunctional(c.default || c))
export const SectionBedrooms = () => import('../../components/section/Bedrooms.vue' /* webpackChunkName: "components/section-bedrooms" */).then(c => wrapFunctional(c.default || c))
export const SectionFacilities = () => import('../../components/section/Facilities.vue' /* webpackChunkName: "components/section-facilities" */).then(c => wrapFunctional(c.default || c))
export const SectionFeatures = () => import('../../components/section/Features.vue' /* webpackChunkName: "components/section-features" */).then(c => wrapFunctional(c.default || c))
export const SectionHouseRules = () => import('../../components/section/HouseRules.vue' /* webpackChunkName: "components/section-house-rules" */).then(c => wrapFunctional(c.default || c))
export const SectionLocation = () => import('../../components/section/Location.vue' /* webpackChunkName: "components/section-location" */).then(c => wrapFunctional(c.default || c))
export const SectionPageContent = () => import('../../components/section/PageContent.vue' /* webpackChunkName: "components/section-page-content" */).then(c => wrapFunctional(c.default || c))
export const SectionSlides = () => import('../../components/section/Slides.vue' /* webpackChunkName: "components/section-slides" */).then(c => wrapFunctional(c.default || c))
export const SectionVillaLayout = () => import('../../components/section/VillaLayout.vue' /* webpackChunkName: "components/section-villa-layout" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
