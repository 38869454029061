
export default {
  vessel: {
    social: {
      fontSize: 'md',
      color: 'primary',
      marginTop: 'sm',
      negativeMarginLeft: 'sm'
    },
    copyright: {
      fontSize: 'sm',
      textAlign: 'center'
    }
  },
  computed: {
    socialAccounts() {
      return [
        {
          name: 'Facebook',
          icon: 'facebook',
          url: 'https://www.facebook.com/villamm'
        },
        {
          name: 'Instagram',
          icon: 'instagram',
          url: 'https://www.instagram.com/villamm'
        }
      ]
    }
  }
}
