import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _37645c87 = () => interopDefault(import('../pages/availability-request.vue' /* webpackChunkName: "pages/availability-request" */))
const _08440926 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _69c9604b = () => interopDefault(import('../node_modules/@evince/site/lib/pages/cookie-policy.vue' /* webpackChunkName: "pages/site/cookie-policy" */))
const _d3444dd6 = () => interopDefault(import('../pages/facilities-rules.vue' /* webpackChunkName: "pages/facilities-rules" */))
const _998f09c2 = () => interopDefault(import('../pages/location.vue' /* webpackChunkName: "pages/location" */))
const _45280486 = () => interopDefault(import('../pages/photo-gallery.vue' /* webpackChunkName: "pages/photo-gallery" */))
const _060c7778 = () => interopDefault(import('../pages/the-villa.vue' /* webpackChunkName: "pages/the-villa" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7d1593c2 = () => interopDefault(import('../node_modules/@evince/site/lib/pages/request.vue' /* webpackChunkName: "pages/site/request" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/availability-request",
    component: _37645c87,
    name: "availability-request___en"
  }, {
    path: "/contact",
    component: _08440926,
    name: "contact___en"
  }, {
    path: "/cookie-policy",
    component: _69c9604b,
    name: "cookie-policy___en"
  }, {
    path: "/facilities-rules",
    component: _d3444dd6,
    name: "facilities-rules___en"
  }, {
    path: "/location",
    component: _998f09c2,
    name: "location___en"
  }, {
    path: "/photo-gallery",
    component: _45280486,
    name: "photo-gallery___en"
  }, {
    path: "/the-villa",
    component: _060c7778,
    name: "the-villa___en"
  }, {
    path: "/",
    component: _0547af50,
    name: "index___en"
  }, {
    path: "/request/:id?",
    component: _7d1593c2,
    name: "request-id___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
