import { render, staticRenderFns } from "./Columns.vue?vue&type=template&id=a9d3446c"
import script from "./Columns.vue?vue&type=script&lang=js"
export * from "./Columns.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterColumn: require('/opt/build/repo/components/footer/Column.vue').default,ScContactDetails: require('/opt/build/repo/node_modules/@evince/site/lib/packages/contact/Details.vue').default,ScContactButton: require('/opt/build/repo/node_modules/@evince/site/lib/packages/contact/Button.vue').default,NewsletterSubscribe: require('/opt/build/repo/components/newsletter/Subscribe.vue').default})
