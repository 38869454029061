import { render, staticRenderFns } from "./image.vue?vue&type=template&id=4635c0c9"
import script from "./image.vue?vue&type=script&lang=js"
export * from "./image.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutImage: require('/opt/build/repo/components/layout/Image.vue').default})
