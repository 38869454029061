import { render, staticRenderFns } from "./Image.vue?vue&type=template&id=0c5131d2"
import script from "./Image.vue?vue&type=script&lang=js"
export * from "./Image.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutHeader: require('/opt/build/repo/components/layout/Header.vue').default,LayoutFooter: require('/opt/build/repo/components/layout/Footer.vue').default,ScCookieBar: require('/opt/build/repo/node_modules/@evince/site/lib/packages/CookieBar.vue').default})
