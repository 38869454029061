
export default {
  data() {
    return {
      quickLinks: [
        {
          path: 'the-villa',
          label: 'pages.villa',
          translate: true
        },
        {
          path: 'photo-gallery',
          label: 'pages.gallery',
          translate: true
        },
        {
          path: 'location',
          label: 'pages.location',
          translate: true
        },
        {
          path: 'facilities-rules',
          label: 'pages.facilities',
          translate: true
        },
        {
          path: 'cookie-policy',
          label: 'site.cookiePolicy',
          translate: true
        }
      ]
    }
  }
}
